import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { isEmpty } from "lodash"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"

import { Register } from "page_components/login_register"

import { isUserLoggedIn } from "utils/functions"

const Login = () => {
  const title = "Rejestracja"
  const [loggedIn, setLoggedIn] = useState(false)

  useEffect(() => {
    const auth = isUserLoggedIn()

    if (!isEmpty(auth)) {
      setLoggedIn(true)
    }

    auth && navigate("/panel-klienta/")
  }, [loggedIn])

  return (
    <Layout isOrder>
      <Breadcrumbs title={title} />
      <PageHeader title={title} />
      <Register setLoggedIn={setLoggedIn} />
    </Layout>
  )
}

export default Login
